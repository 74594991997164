footer.content-info {
  .decorative-footer {
    @include d-flex;
    align-items: flex-end;
    padding-bottom: var(--gap-6);
    height: 302px;
    background: url(../images/bg/footer.png) center center repeat-x;
    background-size: auto 100%;
    text-align: right;
    @include media-breakpoint-down(xs) {
      background: url(../images/bg/footer-sm.png) center center repeat-x;
      background-size: auto 280px;
      height: 280px;
    }
    .finder-form {
      margin-left: auto;
    }
  }
  .footer-links {
    padding: var(--space-md) 0;
    nav {
      ul {
        @include list-normalize;
        li {
          a {
            @include link-underline-hover;
          }
        }
      }
      &.nav-footer {
        ul {
          @include media-breakpoint-up(sm) {
            columns: 2;
          }
          @include media-breakpoint-up(lg) {
            columns: 3;
          }
          li {
            margin-bottom: var(--gap-4);
          }
        }
      }
      &.nav-social {
        margin-top: var(--space-sm);
        @include media-breakpoint-up(lg) {
          text-align: right;
          margin-top: 0;
        }
        ul {
          @include list-inline-block;
          font-size: var(--text-xl);
          li {
            margin-right: var(--gap-4);
            @include media-breakpoint-up(lg) {
              margin-left: var(--gap-4);
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
