// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
.form-control {
  border-width: 2px;
  padding: var(--gap-2) var(--gap-4);
  font-size: var(--text-base);
  text-transform: uppercase;
  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: transparent;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}
.finder-form {
  max-width: 230px;
}
