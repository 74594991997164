html {
  font-size: var(--text-base);
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 0;
  @include media-breakpoint-up(lg) {
    scroll-padding-top: 0;
  }
  body {
    line-height: var(--leading-normal);
    background: var(--color-body-bg);
    color: var(--color-body-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
a {
  @include transition($link-transition);
  color: var(--link-color);
  text-underline-offset: $link-line-distance;
  &:hover,&:active,&:focus {
    color: var(--link-color-hover);
  }
  &:focus-visible {
    @include focus-outline-radius;
  }
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus, &:focus-visible {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
}
img {
  @include img-fluid;
}
.buttons-list {
  @include buttons-list;
}
figure[class*=ratio-] {
  &.ratio-sixteen-nine {
    @include figure-aspect-ratio('16/9');
  }
  &.ratio-five-four {
    @include figure-aspect-ratio('5/4');
  }
  &.ratio-four-five {
    @include figure-aspect-ratio('4/5');
  }
}
.address-info {
  @include list-normalize;
  li {
    margin-bottom: var(--gap-4);
    position: relative;
    padding-left: 1.75em;
    i[class*="fa-"] {
      position: absolute;
      left: 0;
      top: .25em;
    }

  }
}


/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  @include serif-text;
  margin-bottom: var(--space-sm);
  color: var(--headline-color);
  &.header-color-alt {
    color: var(--headline-color-alt);
  }
}
h1 {
  word-wrap: break-word;
  @include media-breakpoint-down(sm) {
    font-size: var(--text-4xl);
  }
}
p,ul,ol {
  @include paragraph-spacing;
}
