// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes


// ELEMENTS
.wp-block-buttons {
  @include paragraph-spacing;
  padding-top: calc(var(--space-xs)/2);
  padding-bottom: calc(var(--space-xs)/2);
}


.has-text-align-center {
  text-align: center;
}
// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}
figure.alignwide {
  @include wp-full-width;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  text-align: center;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  &>img {
    width: 100%;
    max-width: $container-fluid-max !important;
  }
  &>figcaption {
    margin-left: $grid-gutter-width/2;
    margin-right: $grid-gutter-width/2;
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
