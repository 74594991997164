/* GRID
  --------------------------------------- */
@mixin wp-full-width {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}
@mixin wp-full-width-no-top {
  @include wp-full-width;
  margin-top: 0;
  margin-right: 0;
}
@mixin wp-full-width-no-padding {
  @include wp-full-width;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}
@mixin container-full-width {
  max-width: none !important;
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-narrow {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}

@mixin main-column-width-extra {
  @include main-column-width;
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}

/* BLOCK ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-block {
  li {
    display: block;
  }
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin list-normalize-flexbox {
  @include list-normalize;
  @include d-flex;
  &>li {
    @include d-flex;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin last-child-zero-margin-important {
  &>:last-child {
    margin-bottom: 0 !important;
  }
}
@mixin paragraph-spacing {
  margin-bottom: var(--paragraph-spacing);
}
@mixin paragraph-spacing-top {
  margin-top: var(--space-sm);
}
@mixin buttons-list {
  @include list-normalize-inline-block;
  margin-bottom: var(--gap-4);
  li {
    margin: 0 var(--gap-4) var(--gap-4) 0;
  }
}
@mixin sr-only-undo {
  position: relative;
  width: auto;
  height: auto;
  margin: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  border: 0;
}


/* IMAGES
  --------------------------------------- */
@mixin svg-color-fill($color) {
  path {
    fill: $color;
  }
}
@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  isolation: isolate;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}
@mixin header-image {
  position: relative;
  figure {
    @include figure-aspect-ratio('7/5');
    margin-bottom: 0;
    img {
      // opacity: .65;
    }
    @include media-breakpoint-up(md) {
      @include figure-aspect-ratio('7/4');
    }
    @include media-breakpoint-up(lg) {
      @include figure-aspect-ratio('7/3');
    }
  }
  article {
    @include main-column-width-narrow;
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    text-align: center;
    .page-title,.slide-title {
      color: var(--color-white);
      font-size: var(--text-3xl);
      margin: 0;
    }
    .buttons-list {
      margin-top: var(--space-sm);
      li {
        .btn {
          --btn-color: var(--color-green-darker);
          --btn-color-hover: var(--btn-color);
          --btn-bg: var(--color-white);
          --btn-bg-hover: var(--color-green-lighter);
          --btn-border-base: var(--btn-bg);
          --btn-border-hover: var(--btn-bg-hover);
        }
      }
    }
  }
}

/* LINKS & ACTIONS
  --------------------------------------- */
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-underline-hover {
  text-decoration: none;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  text-decoration: none;
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-article-color {
  color: var(--article-link-color);
  &:hover,&:active,&:focus,&:focus-visible {
    color: var(--article-link-color-hover);
  }
}
@mixin link-caret-transition {
  i[class*="fa-"] {
    @include transition($caret-transition);
  }
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(180deg);
    }
  }
}
@mixin link-plus-transition {
  @include link-caret-transition;
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(45deg);
    }
  }
}

@mixin focus-outline {
  outline: 2px solid var(--link-color-focus-visible);
}
@mixin focus-outline-radius {
  @include focus-outline
  border-radius: 2px;
}
@mixin focus-outline-important {
  outline: 2px solid var(--link-color-focus-visible) !important;
  border-radius: 2px !important;
}
@mixin focus-outline-thin {
  @include focus-outline;
  outline-width: 1px;
  outline-offset: 0px;
}

@mixin article-hover {
  figure {
    overflow: hidden;
    img {
      will-change: transform, opacity;
      transition: all ease .4s;
    }
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.05);
      }
    }
  }
}
@mixin article-grid {
  justify-content: center;
  align-items: flex-start;
  .hentry {
    margin-bottom: var(--gap-8);
  }
  a.article-link {
    @include article-hover;
    @include link-no-underline;
    flex-direction: column-reverse;
    height: 100%;
    figure {
      @include figure-aspect-ratio('4/3');
      margin-bottom: var(--gap-3);
    }
    header {
      text-align: center;
      .entry-title {
        @include transition($link-transition);
        font-size: var(--text-xl);
        margin: 0;
        color: var(--article-link-color);
      }
    }
    &:hover,&:active,&:focus,&:focus-visible {
      header {
        .entry-title {
          color: var(--article-link-color-hover);
        }
      }
    }

  }
}


/* ELEVATIONS
  --------------------------------------- */
@mixin elevation-sticky-toc {
  @extend .bg-white;
  box-shadow: var(--elevation-sticky-toc);
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  position: sticky;
	top: 0;
}
@mixin elevation($size) {
  box-shadow: var(--elevation-#{$size}) !important;
  border-radius: $border-radius-elevation;
}


/* ELEVATIONS
  --------------------------------------- */
@mixin elevation($size) {
  box-shadow: var(--elevation-#{$size}) !important;
}


/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: var(--font-sans);
  text-transform: none;
  letter-spacing: var(--tracking-normal);
  line-height: var(--leading-normal);
}
@mixin serif-text {
  text-transform: uppercase;
  letter-spacing: var(--tracking-tight);
  font-family: var(--font-serif);
  font-weight: var(--weight-normal);
  line-height: var(--leading-none);
}
@mixin fa-text {
  font-family: var(--font-awesome);
}
@mixin overline {
  @include sans-text;
  color: var(--overline-color);
  font-size: var(--text-base);
  letter-spacing: var(--tracking-wide);
  line-height: var(--leading-none);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
}
