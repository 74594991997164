/* GRID SETTINGS
  --------------------------------------- */
$grid-gutter-widths: (
  xs: 30px,
  sm: 30px,
  md: 30px,
  lg: 32px,
  xl: 32px
);
$grid-container-margins: (
  xs: 30px,
  sm: 30px,
  md: 30px,
  lg: 32px,
  xl: 32px
);

$grid-gutter-width:         30px;
$main-sm-columns:           12;
$sidebar-sm-columns:        4;

$container-fluid-max:       1140px;
$container-fluid-max-half:  $container-fluid-max/2;


/* COLORS
  --------------------------------------- */
$body-color:            var(--color-body);
$body-bg:               var(--color-bg);
$brand-primary:         var(--color-brand-primary);
$brand-secondary:       var(--color-brand-secondary);

$link-color:            var(--link-color);
$link-hover-color:      var(--link-color-hover);
$link-decoration:       underline;
$link-hover-decoration: underline;
$link-transition:       all .125s ease-in-out;
$btn-transition:        all .125s ease-in-out, border-radius 0s linear;
$caret-transition:      transform .08s linear;
$menu-transition:       visibility 0s, opacity 0.3s ease-in-out;
$indicator-transition:  all .25s ease-in-out;
$link-line-distance:    2px;


/* FONTS
  --------------------------------------- */
$font-family-sans:      var(--font-sans);
$font-family-serif:     var(--font-serif);
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem; //  16
$font-size-sm:          var(--text-sm);   //  14
$font-size-md:          var(--text-md);   //  18
$font-size-lg:          var(--text-lg);   //  20
$font-size-xl:          var(--text-xl);   //  24

$hero-font-size:        var(--text-6xl);  //  64

$h1-font-size:          var(--text-5xl);  //  54
$h2-font-size:          var(--text-4xl);  //  48
$h3-font-size:          var(--text-3xl);  //  40
$h4-font-size:          var(--text-2xl);  //  32
$h5-font-size:          var(--text-xl);   //  24
$h6-font-size:          var(--text-lg);   //  20

$font-weight-normal:    var(--weight-normal);
$font-weight-bold:      var(--weight-bold);
$font-weight-base:      $font-weight-normal;

$display-font-family:   $font-family-base;
$headings-font-family:  $font-family-serif;
$headings-font-weight:  $font-weight-normal;
$headings-line-height:  var(--leading-none);

$border-radius:           var(--border-radius-base);
$border-radius-md:        calc($border-radius*2);
$border-radius-elevation: 0;



/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                var(--text-base);
$input-font-weight:              var(--font-normal);
$input-line-height:              1;
$input-height:                   auto;
$input-bg:                       var(--input-bg);
$input-disabled-bg:              var(--color-gray);
$input-color:                    var(--input-color);
$input-border-color:             var(--input-border-color);
$input-border-width:             1px;
$input-box-shadow:               none;
$input-border-radius:            var(--input-border-radius);
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-focus-box-shadow:         none;
$input-placeholder-color:        var(--input-placeholder);




/* MISC OVERRIDES
  --------------------------------------- */
$lead-font-size:                    auto;
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            auto;

$table-accent-bg:                   var(--color-gray);

