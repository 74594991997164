.card {
  background-color: transparent;
  background-clip: border-box;
  border: none;
  border-top: 1px solid var(--border-color-alt);
  border-radius: 0;
  .card-body {
    padding: 0;
    color: var(--color-body-text);
  }
  .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    &:first-child {
      border-radius: 0;;
    }
    a:not(.btn) {
      @include link-no-underline;
      @include link-caret-transition;
      padding: var(--gap-4) 0;
      color: var(--color-green-dark);
      h3 {
        @include sans-text;
        color: var(--color-green-dark);
        margin: 0;
        font-size: var(--text-md);
        font-weight: var(--weight-bold);
      }
      h3,i[class*="fa-"] {
        color: var(--color-green-dark);
      }
      i[class*="fa-"] {
        margin-left: 1em;
      }
      &:hover,&:active,&:focus,&:focus-visible {
        h3,i[class*="fa-"] {
          color: var(--article-link-color-hover);
        }
      }
    }
  }
}
