:root {
  --btn-color: var(--color-green-darker);
  --btn-color-hover: var(--color-white);
  --btn-color-active: var(--btn-color-hover);
  --btn-color-focus: var(--btn-color-hover);
  --btn-bg: var(--color-green-lighter);
  --btn-bg-hover: var(--color-green);
  --btn-bg-active: var(--btn-bg-hover);
  --btn-bg-focus: var(--btn-bg-hover);
  --btn-border-base: var(--btn-bg);
  --btn-border-hover: var(--btn-bg-hover);
  --btn-border-active: var(--btn-border-hover);
  --btn-border-focus: var(--btn-border-hover);
  --btn-border-focus-visible: var(--btn-border-hover);
}


.btn,.wp-block-button>a.wp-element-button {
  @include transition($btn-transition);
  font-size: var(--text-base);
  line-height: var(--leading-none);
  box-shadow: none !important;
  padding: var(--gap-3) var(--gap-5);
  border: 2px solid var(--btn-border-base);
  border-radius: var(--border-radius-sm);
  position: relative;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
    &:hover, &:active, &:focus, &:focus-visible {
      color: var(--btn-color-hover);
      background-color: var(--btn-bg-hover);
      border-color: var(--btn-border-hover);
      text-decoration: none;
    }
    &:focus-visible {
      @include focus-outline-thin;
      border-color: var(--btn-border-focus-visible);
    }
  }
  &:disabled,&.disabled {
    &:hover, &:active, &:focus, &:focus-visible {
      color: var(--btn-color);
    }
  }
  &.btn-sm {
    font-size: var(--text-sm);
    padding: .5em 1em;
  }
  &.btn-icon-right {
    [class*=fa-] {
      margin-left: .6em;
    }
  }
  &.btn-icon-left {
    [class*=fa-] {
      margin-right: .6em;
    }
  }
  &.btn-display {
    @include serif-text;
    font-size: var(--text-md);
    padding: var(--gap-2) var(--gap-2) var(--gap-1);
    --btn-color: var(--color-green-dark);
    --btn-color-hover: var(--color-white);
    --btn-bg-hover: var(--btn-bg);
    --btn-border-hover: var(--btn-bg-hover);
    --btn-border-active: var(--btn-border-hover);
    --btn-border-focus: var(--btn-border-hover);
    --btn-border-focus-visible: var(--btn-border-hover);
  }
  &.btn-trans {
    --btn-color: var(--color-green-dark);
    --btn-bg: transparent;
    --btn-bg-hover: var(--color-green-dark);
    --btn-border-base: var(--color-green-dark);
    --btn-border-hover: var(--btn-border-base);
    --btn-border-active: var(--btn-border-hover);
    --btn-border-focus: var(--btn-border-hover);
    --btn-border-focus-visible: var(--btn-border-hover);
    border-width: 1px;
    &.current {
      --btn-color: var(--btn-color-hover);
      --btn-bg: var(--btn-bg-hover);
    }
  }
}
