.tribe-events {
  .tribe-events-l-container {
    padding: var(--space-md) 0 0;
    min-height: 300px;
    .empty-events {
      h2,h3,p {
        margin-bottom: var(--space-base);
      }
    }
    .event-item-card {
      padding: var(--gap-6) 0;
      border-top: 1px solid var(--border-color-alt);
      .details {
        flex-direction: column;
      }
      time {
        text-align: center;
        border-radius: var(--border-radius-sm);
        padding: var(--gap-4) var(--gap-2) var(--gap-2);
        min-width: 96px;
        min-height: 96px;
        margin-left: var(--space-sm);
        span {
          display: block;
          &.month {
            text-transform: uppercase;
            line-height: var(--leading-none);
            margin-bottom: var(--gap-3);
          }
          &.date {
            @include serif-text;
            line-height: .9;
            font-size: var(--text-4xl);
          }
        }
      }
      h3 {
        font-size: var(--text-xl);
        margin-bottom: var(--gap-3);
      }
      .address-info {
        margin-bottom: var(--gap-1);
        li {
          margin-bottom: var(--gap-2);
        }
      }
      @include media-breakpoint-down(sm) {
        header {
          @include d-flex;
          margin-bottom: var(--gap-3);
          justify-content: space-between;
        }
        time {
          padding: var(--gap-3) var(--gap-2) var(--gap-1);
          font-size: var(--text-xs);
          min-width: 64px;
          min-height: 64px;
          span {
            &.month {
              margin-bottom: var(--gap-2);
            }
            &.date {
              font-size: var(--text-xl);
            }
          }
          span.date {
            font-size: var(--text-xl);
          }
        }
        &>time {
          display: none
        }
      }
    }
  }
}
