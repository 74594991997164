.bg-white {
  --color-body-bg: var(--color-white);
  --color-body-text: var(--color-body-text);
}
.bg-black {
  --color-body-bg: var(--color-black);
  --color-body-text: var(--color-white);
}
.bg-yellow-light {
  --color-accent: var(--color-yellow-accent);
}
.bg-green-light,.bg-green-lighter {
  --link-color: var(--color-green-darker);
  --link-color-hover: var(--color-white);
  --link-color-focus-visible: var(--color-white);
}
.bg-green-light {
  --color-body-bg: var(--color-green-light);
}
.bg-green-lighter {
  --color-body-bg: var(--color-green-lighter);
}
.bg-green,.bg-green-dark,.bg-green-darker,.bg-green-darkest {
  --color-body-text: var(--color-white);
  --link-color: var(--color-white);
  --link-color-hover: var(--color-green-lighter);
  --link-color-focus-visible: var(--color-green-lighter);
}
.bg-green {
  --color-body-bg: var(--color-green);
}
.bg-green-dark {
  --color-body-bg: var(--color-green-dark);
}
.bg-green-darker {
  --color-body-bg: var(--color-green-darker);
}
.bg-green-darkest {
  --color-body-bg: var(--color-green-darkest);
}
[class*="bg-"] {
  background: var(--color-body-bg);
  color: var(--color-body-text);
}
