.single-beer {
  .beer-details {
    padding: var(--space-md) 0 var(--space-lg);
    .beer-stats {
      @include list-normalize;
      margin: var(--space-sm) 0;
    }
    .finder-form {
      display: block;
      max-width: 300px;
    }
  }
  .beer-gallery {
    @include list-normalize;
    @include make-row();
    margin-left: -$grid-gutter-width*.25;
    margin-right: -$grid-gutter-width*.25;
    @include media-breakpoint-down(sm) {
      margin-bottom: var(--space-md);
    }
    li {
      @include make-col-ready();
      @include make-col(3);
      padding: 0 $grid-gutter-width*.25;
      margin-bottom: $grid-gutter-width/2;
      order: 1;
      cursor: pointer;
      figure {
        @include figure-aspect-ratio('1/1');
        margin-bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        img {
          opacity: .8;
        }
      }
      &.current {
        @include make-col(12);
        order: 0;
        figure {
          img {
            opacity: 1;
          }
        }
      }
      // &:not(.current) {
      //   figure {
      //     @include figure-aspect-ratio('1/1');
      //   }
      // }
    }
  }
}
.beer-filter {
  @include list-normalize;
  @include list-inline-block;
  margin-bottom: var(--gap-10);
  text-align: center;
  li {
    margin: 0 var(--gap-2) var(--gap-2);
  }
}
