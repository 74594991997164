@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}


:root {

  /* FONTS/TYPE
  --------------------------------------- */
  /* font-family */
    --font-sans: 'Rubik', "Helvetica Neue", Arial, sans-serif;
    --font-serif: 'InkGothicInked-Regular',Georgia, "Times New Roman", Times, serif;
    --font-display: var(--font-sans);
    --font-awesome: "Font Awesome 6 Pro";

  /* line-height */
    --leading-none:     1;
    --leading-tightest: 1.1875;
    --leading-tight:    1.2;
    --leading-md:       1.4;
    --leading-normal:   1.5;

  /* letter-spacing */
    --tracking-tight:   -0.01em;
    --tracking-normal:  0em;
    --tracking-more:    .01em;
    --tracking-wide:    .125em;

  /* font-weight */
    --weight-normal:    400;
    --weight-medium:    500;
    --weight-bold:      700;

  /* font-size */
    --text-base: 1rem;      // 16
    --text-xs:   .75rem;    // 12
    --text-sm:   .875rem;   // 14
    --text-md:   1.125rem;  // 18
    --text-lg:   1.25rem;   // 20 / h6
    --text-xl:   1.5rem;    // 24 / h5
    --text-2xl:  2rem;      // 32 / h4
    --text-3xl:  2.5rem;    // 40 / h3
    --text-4xl:  3rem;      // 48 / h2
    --text-5xl:  3.375rem;  // 54 / h1
    --text-6xl:  4rem;      // 64 / HERO


  /* COLORS
  --------------------------------------- */
    --color-black:            #000;
    --color-white:            #fff;

    --color-gray:             #D9D9D9;
    --color-gray-dark:        #424141;
    --color-gray-darker:      #262828;

    --color-green:            #266041;
    --color-green-light:      #62A345;
    --color-green-lighter:    #93C83D;
    --color-green-dark:       #1C4735;
    --color-green-darker:     #143329;
    --color-green-darkest:    #132620;

    --color-brand-primary:    var(--color-green);
    --color-brand-secondary:  var(--color-green-lighter);

    --color-body-bg:          var(--color-white);
    --color-body-text:        var(--color-green-dark);

    --color-status-error-orig:#FF7523;
    --color-status-error:     #AF0069;
    --color-status-info:      #73A5E9;
    --color-status-success:   #71B7A5;
    --color-status-warning:   #EEBE32;

    --link-color: var(--color-body-text);
    --link-color-hover: var(--color-brand-primary);
    --link-color-active: var(---link-color-hover);
    --link-color-focus: var(--link-color-hover);
    --link-color-focus-visible: var(--color-status-success);

    --article-link-color: var(--color-green);
    --article-link-color-hover: var(--color-body-text);

    --headline-color: var(--color-brand-primary);
    --headline-color-alt: var(--color-body-text);

    --border-color: var(--color-gray);
    --border-color-alt: var(--color-green-light);
    --border-color-alt-light: var(--color-green-lighter);
    --border-radius-base: 6px;
    --border-radius-sm:   4px;

    --color-accent: var(--border-color);

    --ag-button-color-background: var(--color-green-lighter);
    --ag-button-color-hover: var(--ag-button-color-background);
    --ag-button-color-text: var(--color-green-darker);
    --ag-button-color-text-hover: var(--color-white);


  /* MARGINS/PADDING
  --------------------------------------- */
  /* fluid-spacer */
    --space-xs: clamp(12px, calc(12px + (16 - 12) * ((100vw - 375px) / (1600 - 375))), 16px);
    --space-sm: clamp(20px, calc(20px + (32 - 20) * ((100vw - 375px) / (1600 - 375))), 32px);
    --space-md: clamp(30px, calc(30px + (48 - 30) * ((100vw - 375px) / (1600 - 375))), 48px);
    --space-lg: clamp(50px, calc(50px + (80 - 50) * ((100vw - 375px) / (1600 - 375))), 80px);
    --space-xl: clamp(80px, calc(80px + (128 - 80) * ((100vw - 375px) / (1600 - 375))), 128px);
    --space-xxl: clamp(130px, calc(130px + (160 - 130) * ((100vw - 375px) / (1600 - 375))), 160px);
    --space-base: calc(var(--space-xs)*1.5); // 24

    --paragraph-spacing: var(--space-sm);


  /* static gaps*/
    --static-size-base: 4px;
    --gap-1: var(--static-size-base);   // 4
    --gap-2: calc(var(--gap-1)*2);      // 8
    --gap-2-5: calc(var(--gap-1)*2.5);  // 10
    --gap-3: calc(var(--gap-1)*3);      // 12
    --gap-4: calc(var(--gap-1)*4);      // 16
    --gap-5: calc(var(--gap-1)*5);      // 20
    --gap-6: calc(var(--gap-1)*6);      // 24
    --gap-7: calc(var(--gap-1)*7);      // 28
    --gap-8: calc(var(--gap-1)*8);      // 32
    --gap-9: calc(var(--gap-1)*9);      // 36
    --gap-10: calc(var(--gap-1)*10);    // 40
    --gap-11: calc(var(--gap-1)*11);    // 44
    --gap-12: calc(var(--gap-1)*12);    // 48


  /* INPUTS
  --------------------------------------- */
    --input-color: var(--color-brand-primary);
    --input-bg: var(--color-white);
    --input-border-color: var(--color-green-lighter);
    --input-border-radius: var(--border-radius-sm);
    --input-placeholder: var(--input-color);


  /* MISC
  --------------------------------------- */
    --avatar-size: 48px;
    --headshot-size: 64px;
    --highlight-icon-size: 72px;


  /* ELEVATIONS
  --------------------------------------- */
    --elevation-base: 0px 0px 14px rgba(0, 0, 0, 0.25);
    --elevation-md: 2px 4px 14px 2px rgba(0, 0, 0, 0.25);;
}
