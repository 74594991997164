/* GENERAL BLOCKS
  --------------------------------------- */
.wp-full-width {
  @include wp-full-width-no-padding;
}
.pdm-block {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  &:not(.bg-white) {
    &+.pdm-block.bg-white {
      margin-top: calc(var(--space-lg)*-1);
    }
  }
  &+.pdm-block:not(.bg-white) {
    margin-top: calc(var(--space-lg)*-1);
  }
  &.bg-white {
    margin-top: 0;
    // &+.pdm-block.bg-white {
    //   margin-top: calc(var(--space-lg)*-1);
    // }
    &.slideshow-block {
      margin-bottom: 0;
    }
  }
}
main.main {
  &>:first-child {
    &.pdm-block {
      margin-top: 0;
      &.slideshow-block {
        padding-bottom: var(--space-md);
      }
    }
    &.sr-only {
      &+.slideshow-block {
        padding-bottom: var(--space-md);
      }
    }
  }
  &>:last-child {
    &.pdm-block {
      margin-bottom: calc(var(--space-lg)*-1);
    }
  }
}

.link-tiles-grid,.beers-grid {
  @include article-grid;
}

/* SPECIFIC BLOCKS
  --------------------------------------- */
.accordion-block {
  margin: var(--space-md) 0 var(--space-lg);
  border-bottom: 1px solid var(--border-color-alt);
  .accordion-title {
    font-size: var(--text-2xl);
    text-align: center;
    margin-bottom: var(--gap-8);
  }
}
.beers-block {
}
.contact-info-block {
  article {
    border: 1px solid var(--border-color-alt-light);
    .row {
      margin: 0;
      [class*="col-"] {
        @include last-child-zero-margin;
        padding: var(--space-md);
        &:first-child {
          border-bottom: 1px solid var(--border-color-alt-light);
          @include media-breakpoint-up(md) {
            border-right: 1px solid var(--border-color-alt-light);
            border-bottom: none;
          }
        }
      }
    }
  }
  .contact-info {
    @include last-child-zero-margin;
    p {
      margin-bottom: var(--gap-4);
    }
  }
}
.gallery-block {
  padding-top: var(--space-md);
  margin-top: 0;
  margin-bottom: 0;
  .gallery-row {
    margin-left: -$grid-gutter-width*.25;
    margin-right: -$grid-gutter-width*.25;
    .gallery-item  {
      @include make-col-ready();
      @include make-col(12);
      padding: 0 $grid-gutter-width*.25;
      margin-bottom: $grid-gutter-width/2;
    }
    figure {
      @include figure-aspect-ratio('7/3');
      margin-bottom: 0;
    }
    &.count-3 {
      .gallery-item  {
        @include media-breakpoint-up(md) {
          @include make-col(4);
        }
        figure {
          @include figure-aspect-ratio('13/10');
        }
      }
      &.asymmetrical {
        .gallery-item  {
          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
          figure {
            @include figure-aspect-ratio('7/5');
          }
          &:first-child,&:last-child {
            @include media-breakpoint-up(md) {
              @include make-col(3);
            }
            figure {
              @include figure-aspect-ratio('8/11.91');
              @include media-breakpoint-up(lg) {
                @include figure-aspect-ratio('8/11.816');
              }
              @include media-breakpoint-up(xl) {
                @include figure-aspect-ratio('8/11.7505');
              }
            }
          }
        }
      }
    }
    &.count-2 {
      .gallery-item  {
        @include media-breakpoint-up(md) {
          @include make-col(6);
          figure {
            @include figure-aspect-ratio('3/2');
          }
        }
      }
      &.asymmetrical {
        .gallery-item {
          figure {
            @include figure-aspect-ratio('2/1');
          }
          @include media-breakpoint-up(md) {
            @include make-col(8);
          }
          &:last-child {
            @include media-breakpoint-up(md) {
              @include make-col(4);
            }
            figure {
              @include figure-aspect-ratio('2/2.0682');
              @include media-breakpoint-up(lg) {
                @include figure-aspect-ratio('2/2.05015');
              }
              @include media-breakpoint-up(xl) {
                @include figure-aspect-ratio('2/2.0417');
              }
            }
          }
        }
      }
    }
  }
}
.menu-block {
  margin: var(--space-lg) 0;
  .menu-title {
    font-size: var(--text-2xl);
    text-align: center;
    margin-bottom: var(--space-sm);
  }
  .menu-list-item {
    @include last-child-zero-margin;
    padding: var(--gap-6) 0;
    border-top: 1px solid var(--border-color-alt);
    &:last-child {
      border-bottom: 1px solid var(--border-color-alt);
    }
    h3 {
      font-size: var(--text-xl);
      margin-bottom: var(--gap-2);
      color: var(--headline-color-alt);
      small {
        @include sans-text;
        display: inline-block;
        margin-left: var(--gap-2);
        font-weight: var(--weight-normal);
        font-size: var(--text-base);
      }
    }
  }
}

.link-tiles-block {
  padding-bottom: var(--space-xs);
}
.slideshow-block {
  .slide-item {
    @include header-image;
  }
}


.page-header {
  @include last-child-zero-margin;
  // padding: var(--space-md) 0 var(--space-lg);
  padding: var(--space-md) 0;
  text-align: center;
  margin-bottom: 0;
  article {
    @include main-column-width;
    @include last-child-zero-margin;
    .buttons-list {
      li {
        margin: 0 var(--gap-2);
      }
    }
  }
  &+.pdm-block {
    margin-top: 0 !important;
    &.bg-white {
      padding-top: 0 !important;
    }
  }
  &+.accordion-block,&+figure.alignwide,&+.menu-block,&+p {
    margin-top: var(--space-md);
  }
  .row {
    position: relative;
    overflow: hidden;
  }
  &.image-header {
    @include header-image;
  }
  .page-id-82 & { // our beer page
    padding-bottom: var(--space-md);
    &+.beers-block {
      padding-top: var(--space-md);
    }
  }
}
