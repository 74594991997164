.slick-dots {
  --slick-dot-size: 12px;
  --slick-dot-color: var(--grayscale-neutral-darkest);
  --slick-dot-color-active: var(--color-green);

  @include list-normalize-inline-block;
  position: absolute;
  bottom: var(--space-xs);
  left: 0;
  li {
    position: relative;
    display: inline-block;
    height: var(--slick-dot-size);
    width: var(--slick-dot-size);
    margin: 0px 8px 0 0;
    padding: 0px;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: var(--slick-dot-size);
      width: var(--slick-dot-size);
      outline: 0;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:focus {
        outline: 0;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "\f111";
        width: var(--slick-dot-size);
        height: var(--slick-dot-size);
        font: var(--fa-font-solid);
        font-size: var(--slick-dot-size);
        line-height: var(--slick-dot-size);
        text-align: center;
        color: var(--slick-dot-color);
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

    }

    &.slick-active button:before {
      color: var(--slick-dot-color-active);
      opacity: 1 !important;
    }
  }
}
/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 24px;
  width: 24px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: var(--color-white);
  top: 50%;
  margin-top: -12px;
  padding: 0;
  border: none;
  outline: none;
  z-index: 1000;
  &:focus {
    outline: none;
  }
  &:hover,&:active,&:focus,&:focus-visible {
    &:before {
      color: var(--color-white);
    }
  }
  &.slick-disabled:before {
    opacity: 0.25;
  }
}
.slick-prev:before, .slick-next:before {
  @include transition($link-transition);
  font:var(--fa-font-solid);
  font-size: 24px;
  line-height: 1;
  color: var(--color-white);
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: 20px;
    &:before {
        content: '\f0a8';
    }
}
.slick-next {
    right: 20px;
    &:before {
        content: '\f0a9';
    }
}
// .slick-lightbox {
//   background-color: rgba(1, 83, 63, .85) !important;
// }
