header.banner {
  padding: var(--gap-4) 0;
  .ancillary {
    margin-bottom: var(--gap-4);
    .alert-msg {
      @include last-child-zero-margin;
      font-size: var(--text-sm);
    }
  }
  .identity {
    margin-bottom: var(--gap-4);
    background: url(../images/bg/header.png) center center repeat-x;
    background-size: auto 32px;
    .brand {
      text-align: center;
      padding: 0 var(--gap-6);
      max-width: calc(360px + var(--gap-12));
      // img {
      //   max-width: 360px;
      // }
    }
  }
  .navigation {
    .navbar-toggler {
      padding: var(--gap-4);
      border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
      width: 100%;
      font-size: var(--text-base);
      font-weight: var(--weight-medium);
      text-transform: uppercase;
      [class*=fa-] {
        font-size: var(--text-base);
        height: var(--text-base);
      }
      .fa-bars {
        display: none;
      }
      &.collapsed {
        border-radius: var(--border-radius-base);
        .fa-bars {
          display: inline-block;
        }
        .fa-close {
          display: none;
        }
      }
    }
    .nav-primary {
      @include main-column-width-full;
      text-transform: uppercase;
      font-weight: var(--weight-medium);
      &>ul {
        padding: var(--gap-3) var(--gap-2);
        margin: 0 auto;
        border-radius: var(--border-radius-base);
        &>li {
          display: inline-block;
          font-size: 15px;
          line-height: var(--leading-none);
          &>a.nav-link {
            display: inline-block;
            padding: 0 var(--gap-4);
            border-left: 1px solid var(--color-green-light);
            &.dropdown-toggle:after {
              display: inline-block;
              margin-left: .35em;
              vertical-align: inherit;
              content: "\f0d7";
              font-family: var(--font-awesome);
              font-weight: var(--weight-bold);
              border: none;
              @include media-breakpoint-down(md) {
                float: right;
              }
            }
            &+.dropdown-menu.show {
              transform: none !important;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border: none;
              top: 25px !important;
              padding: var(--gap-2) 0;
              li {
                .dropdown-item {
                  font-size: 15px;
                  font-weight: inherit !important;
                  padding: var(--gap-2) var(--gap-4);
                  @include hover-focus-active {
                    background-color: transparent;
                    color: var(--link-color-hover) !important;
                  }
                }
              }
            }
          }
          &.show {
            &>a.nav-link {
              &.dropdown-toggle:after {
                transform: rotate(180deg);
              }
            }
          }
          &:first-child {
            &>a.nav-link {
              border-left: none;
            }
          }
          &.external-link {
            &>a.nav-link {
              &:after {
                // display: inline-block;
                // margin-left: .35em;
                // vertical-align: inherit;
                // content: "\f08e";
                // font-family: var(--font-awesome);
                // // font-weight: var(--weight-bold);
                // border: none;
                // @include media-breakpoint-down(md) {
                //   float: right;
                // }
              }
            }
          }
        }
      }
      @include media-breakpoint-down(md) {
        &>ul {
          @include list-block;
          flex-direction: column;
          border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
          padding: 0;
          &>li {
            &>a.nav-link {
              border-left: none;
              border-top: 1px solid var(--color-green-light);
              display: block !important;
              padding: var(--gap-3) var(--gap-4);
              &+.dropdown-menu.show {
                position: relative !important;
                top: auto !important;
                border-radius: 0;
                float: none;
                padding: var(--gap-3) 0;
                margin: 0;
                li {
                  .dropdown-item {
                    padding: var(--gap-3) var(--gap-4);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
